import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from 'react-bootstrap';
import TweetDeckColumn from './tweetdeckcolumns';
import { isMobile } from 'react-device-detect';
import { APPPATH, TrendingMentionsHelper } from '../../modules/helper.module';
import ColorPreferenceSlider from '../shared/preferenceRangeSlider/preferenceslider';
import ShowDetailIcon from '../common/showDetailIcon';
import HelperVideo from './helperVideo';
import FavCoin from './favCoin';
import * as moment from 'moment';
import TweetDeckFiller from './tweetDeckFiller';
import Swipeable from '../swipeable/swipeable';
import InitialColumnLoader from './initcolumnloader';
import { connect } from 'react-redux';
import { setcolumnlist, DisableLoader } from '../../actions/settings.action';
import { syncsavedsearchcolumnordersettings } from '../../actions/search.action';
import { IforexInstrumentId } from '../../static_data/iforexinstrumentids';

class TweetDeck extends Component {
  constructor(props) {
    super();
    let feedList = this.makeFeedList(props);
    // console.log(feedList)
    // let { FeedListRef, BeforeActiveTab, AfterActiveTab } = this.getBeforeAfterActiveTab(feedList, 'Trending Mentions');
    let StoryState = props.storyState;
    // if (isMobile) {
    //   StoryState.TabMode = true;
    // }
    // console.log(props.coin_column_preferences.length)
    this.state = {
      storyState: StoryState,
      TDeckDomRect: {},
      TabDomRect: {},
      TdeckColDomRect: {},
      FeedList: feedList,
      feedListRef: [],
      NewTab: false,
      NewColumnIsLastColumn: false,
      ActiveTab: Object.keys(feedList)[0],
      coin_column_preferences_length: props.coin_column_preferences.length,
      BeforeActiveTab: '',
      AfterActiveTab: '',
      tmAutoRefresh: true,
      pinHover: false,
      naceHover: false,
      swGrayHover: false,
      swBlackHover: false,
      ssGrayHover: false,
      ssBlackHover: false,
      ssCloseHover: false,
      TMCloseHover: false,
      AllSecFilter: props.getAllSecFilter(),
      coinDetailsHover: false,
      coinDetailsCollapse: false,
      CoinEventListcollapse: props.CD_Collapsed_View,
      MainEventListcollapse: props.Event_Collapsed_View,
      SearchListCollapse: props.Search_Collapsed_View,
      // SavedSearchListCollapse: props.SavedSearch_Collapsed_View,
      mainEventListcollapseHover: false,
      coinEventListcollapseHover: false,
      searchEventListcollapseHover: false,
      savedSearchEventListcollapseHover: false,
      hidePreferenceInSearchFlag: false,
      hidePreferenceInSavedSearchFlag: {},
      RecentUpdateView: StoryState.RecentUpdateView,
      searchSaveDatacount: Object.keys(StoryState.searchSaveData).length,
      pinnedSearchCount: StoryState.pinnedSearchCount,
      columnorderinitload: StoryState.columnorderlist_initload,
      savedsearchinitload: StoryState.searchsavedata_initload
    };
    window.showCoinColumnFromText = (Security, Ticker, listkey = false, tdeckType = false, direction = false, eventid = false, headline = false) => {
      // console.log('Security Params')
      // console.log(Security, Ticker, listkey, tdeckType)

      this.showCoinColumn(Security, Ticker, listkey, tdeckType, direction, eventid, headline);
    };
    const urlSearchParams = new URLSearchParams(props.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const flag = params && Object.keys(params).length === 0 && Object.getPrototypeOf(params) === Object.prototype;
    if (!flag && params && params.eventId) {
      props.getEventDetails(params.eventId, props.userData.username);
      props.showModal('Event');
      window.Mixpanel.MixpanelProps.UserName = props.userData.username;
      window.Mixpanel.MixpanelProps.EventId = params.eventId;
      window.Mixpanel.actions.identify(props.userData.username);
      if (params.utm_source === 'browsernotifications') {
        window.Mixpanel.actions.track(`Browser Notification Clicked`, window.Mixpanel.MixpanelProps).then((data) => {
          window.Mixpanel.actions.people.set({
            UserName: props.userData.username,
          });
        });
      } else {
        window.Mixpanel.actions.track(`Real Time Events ${params.utm_source ? '-' + params.utm_source : params.source ? '-' + params.source : ''}`, window.Mixpanel.MixpanelProps).then((data) => {
          window.Mixpanel.actions.people.set({
            UserName: props.userData.username,
          });
        });
      }

    }
    this.OverlayRef = React.createRef();
    this.TouchStartX = '';
    this.TouchStartY = '';
    this.TouchInitX = '';
    this.TouchInitY = '';
    this.hasTouch = false;
    this.longTouch = false;
    this.scrollPos = 0;
    this.ActiveTab = Object.keys(feedList)[0];
    this.BeforeActiveTab = '';
    this.AfterActiveTab = '';
    this.ColTouchStartDetails = [];
    this.loadTime = moment();
    this.newTabTM = false;
    // console.log(this.ActiveTab)
    // sessionStorage.setItem(`${props.userData.username}lastActiveTab`, this.ActiveTab);

  }
  hidePreferenceInSearch = (flag) => {
    this.setState({
      ...this.state,
      hidePreferenceInSearchFlag: flag,
    });
  };
  hidePreferenceInSavedSearch = (savedId, flag) => {
    // console.log(savedId, flag)
    this.setState({
      ...this.state,
      hidePreferenceInSavedSearchFlag: {
        ...this.state.hidePreferenceInSavedSearchFlag,
        [`${savedId}`]: flag,
      },
    });
  };
  showCoinColumn = (Security, Ticker, listkey = false, tdecktype = false, direction = false, eventid = false, headline = false) => {
    // console.log('in here', tdecktype)
    // console.log('in here', Security, Ticker, listkey, tdecktype, direction)
    // console.log('in here', this.props.render)
    // console.log(this.props.userData.loginlessuser)
    // return;
    if (this.props.render !== 'iframe') {
      let option = {
        SecurityTicker: Ticker,
        SecurityName: Security,
      }
      // alert('pinconcolumnmuted')
      this.props.pinCoinColumn(option, listkey)
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      // window.Mixpanel.MixpanelProps.org = this.props.userData.loginlessuser;
      // window.Mixpanel.MixpanelProps.render = this.props.render;
      // window.Mixpanel.MixpanelProps.theme = this.props.Theme;
      // window.Mixpanel.MixpanelProps.lang = this.props.lang;
      window.Mixpanel.MixpanelProps.asset = Security;
      // window.Mixpanel.MixpanelProps.assetId = instrumentid;
      // window.Mixpanel.MixpanelProps.direction = direction;

      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track(`Show Coin Column`, window.Mixpanel.MixpanelProps).then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username,
        });
      });
    } else if (this.props.render === 'iframe') {
      if (this.props.userData.loginlessuser === 'Iforex') {
        let deeplink = '';
        let instrumentid = '';
        if (typeof IforexInstrumentId[`${Security}`] !== 'undefined') {
          instrumentid = IforexInstrumentId[`${Security}`];
          if (direction !== false) {
            deeplink = `https://trader.iforex.com/webpl3/Account/Redirect/NewDeal?instrumentId=${instrumentid}&source=Algense&orderDir=${direction}`;
            let data = {
              instrumentId: instrumentid,
              orderDir: direction
            }
            window.parent.postMessage({
              // Deeplink: deeplink,
              AssetData: JSON.stringify(data),
            }, "*");


            window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
            window.Mixpanel.MixpanelProps.org = this.props.userData.loginlessuser;
            window.Mixpanel.MixpanelProps.render = this.props.render;
            window.Mixpanel.MixpanelProps.theme = this.props.Theme;
            window.Mixpanel.MixpanelProps.lang = this.props.lang;
            window.Mixpanel.MixpanelProps.asset = Security;
            window.Mixpanel.MixpanelProps.assetId = instrumentid;
            window.Mixpanel.MixpanelProps.direction = direction;
            window.Mixpanel.MixpanelProps.EventId = eventid;
            window.Mixpanel.MixpanelProps.headline = headline;

            window.Mixpanel.actions.identify(this.props.userData.username);
            window.Mixpanel.actions.track(`Trade Button Clicked`, window.Mixpanel.MixpanelProps).then((data) => {
              window.Mixpanel.actions.people.set({
                UserName: this.props.userData.username,
              });
            });

          }
        }
      }
    }

    // if(tdecktype && tdecktype === 'event') {
    // this.props.TMViewDetails(option);
    // this.props.pinCoinColumn(option, listkey)
    // this.storyTabSelect('Trending Mentions Details');
    // } else {
    // console.log(option, listkey)
    // if(TrendingMentionsHelper.FreeCoinFilter(option)) {
    // this.props.TMViewDetails(option);
    // this.props.pinCoinColumn(option, listkey)
    // this.storyTabSelect('Trending Mentions Details');
    // } else {
    //   this.props.toggleConfirmPopup(true, true, 'Enable to see details for all coins')
    // }
    // }

  };

  componentDidMount() {
    // console.log(this.state.CoinEventListcollapse, this.state.MainEventListcollapse, this.state.SearchListCollapse, this.state.SavedSearchListCollapse)
    let prevTab = sessionStorage.getItem(`${this.props.userData.username}lastActiveTab`);
    // console.log('componentdidmount')
    // console.log(prevTab, this.ActiveTab)
    // console.log(this.state.FeedList)
    // console.log(Object.keys(this.state.FeedList).indexOf(prevTab))
    if (prevTab) {
      if (Object.keys(this.state.FeedList).indexOf(prevTab) !== -1) {
        sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, prevTab);
      } else {
        sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.ActiveTab);
      }
    } else if (!prevTab) {
      sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.ActiveTab);
      // sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, 'Trending Mentions__0');
      // sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.state.ActiveTab);
    }
    // else {
    // if (this.state.FeedList !== {} && Object.keys(this.state.FeedList).length) {
    //   if (Object.keys(this.state.FeedList).indexOf(prevTab) === -1) {
    //     sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.ActiveTab);
    //     // sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.state.ActiveTab);
    //   }
    // }
    // sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.ActiveTab);
    // }

    let tdeckDom = this.setTDeckDomRect();
    let tabDom = this.setTabDomRect();
    // console.log(sessionStorage.getItem(`${this.props.userData.username}lastActiveTab`))
    let { FeedListRef, BeforeActiveTab, AfterActiveTab } = this.getBeforeAfterActiveTab(
      this.state.FeedList,
      sessionStorage.getItem(`${this.props.userData.username}lastActiveTab`)
    );
    // console.log( FeedListRef, BeforeActiveTab, AfterActiveTab)
    this.ActiveTab = sessionStorage.getItem(`${this.props.userData.username}lastActiveTab`);
    this.BeforeActiveTab = BeforeActiveTab;
    this.AfterActiveTab = AfterActiveTab;
    this.setState({
      ...this.state,
      // ActiveTab: sessionStorage.getItem(`${this.props.userData.username}lastActiveTab`),
      feedListRef: JSON.parse(JSON.stringify(FeedListRef)),
      // BeforeActiveTab: BeforeActiveTab,
      // AfterActiveTab: AfterActiveTab,
      TDeckDomRect: tdeckDom,
      TabDomRect: tabDom,
    }, () => {
      if (isMobile) {
        this.props.setSwipeableOptions({
          feedlist: this.state.FeedList,
          ActiveTab: this.ActiveTab,
          BeforeActiveTab: this.BeforeActiveTab,
          AfterActiveTab: this.AfterActiveTab,
        })
      }

    });
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.NewTab && !prevState.Newtab) {
  //     console.log('in cup')
  //      if(isMobile) {
  //        let FeedListKeys = Object.keys(this.state.FeedList)
  //        let TDeckDomRect = this.state.TDeckDomRect
  //       let scrollDiv = document.getElementsByClassName('cScroll-Mob')[0];
  //       if (scrollDiv) {
  //         console.log(this.state.FeedList, FeedListKeys, this.ActiveTab, this.BeforeActiveTab, this.AfterActiveTab, FeedListKeys.indexOf(this.ActiveTab) ,scrollDiv.scrollWidth, FeedListKeys.length, TDeckDomRect.width)
  //         // scrollDiv.scrollLeft = ((scrollDiv.scrollWidth/FeedListKeys.length)*FeedListKeys.indexOf(ActiveTab)) -10;
  //         setTimeout(() => {
  //           scrollDiv.scroll({
  //             // left: ((scrollDiv.scrollWidth/FeedListKeys.length)*FeedListKeys.indexOf(ActiveTab)) -10,
  //             left: ((scrollDiv.scrollWidth/FeedListKeys.length)*FeedListKeys.indexOf(this.ActiveTab)) -10,
  //             behavior: 'smooth'
  //           })
  //         }, 1000)

  //       }
  //      }

  //   }

  // }

  closecoincolumn = (index, SecName, SrcFilter, columnName, columnIndex, listkey) => {
    // console.log(index, SecName, SrcFilter, columnName, columnIndex, listkey)
    // return;
    // console.log(this.state.storyState.tmDetailPreferences, this.state.FeedList)
    // document.getElementById(`${columnName}${columnIndex}`).style.display = 'none';
    let flist = this.state.FeedList
    delete flist[`${columnName}`]
    this.setState({
      FeedList: flist
    }, () => {
      this.props.CloseCoinDetailColumn(index, listkey)
    })
  }
  componentWillReceiveProps(nextProps, prevState) {
    // console.log(this.state.storyState.RecentUpdateView, nextProps.storyState.RecentUpdateView)
    // console.log(this.state.storyState.searchCount < nextProps.storyState.searchCount)
    // console.log(this.state.storyState.searchCount , nextProps.storyState.searchCount)
    // console.log(this.props.existingColumnName , nextProps.existingColumnName)
    // console.log('SearchSavedata:-' , nextProps.searchSaveData)
    let tdeckDom = this.setTDeckDomRect();
    let tabDom = this.setTabDomRect();
    let feedList = this.makeFeedList(nextProps);
    // console.log(feedList)
    if (!Object.keys(feedList).length) {
      // this.props.modalClose();
      nextProps.DisableLoader()
    } else {
      this.ActiveTab = Object.keys(feedList)[0]
    }
    // console.log(this.ActiveTab)
    let newTab = false;
    let NewColumnIsLastColumn = false;
    let StoryState = nextProps.storyState;
    // console.log(feedList)
    // if (isMobile) {
    //   StoryState.TabMode = true;
    // }
    // console.log(this.state.storyState.searchCount , nextProps.storyState.searchCount)
    // console.log(this.props.storyState.singleCoinSearch , nextProps.storyState.singleCoinSearch)
    let prevTab = sessionStorage.getItem(`${this.props.userData.username}lastActiveTab`);
    if (!prevTab || Object.keys(feedList).indexOf(prevTab) === -1) {
      sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.ActiveTab);
      prevTab = this.ActiveTab
      newTab = true;
    } else {
      if (prevTab) {
        if (Object.keys(feedList).indexOf(prevTab) === 0) {
          sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, prevTab);
        } else {
          sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.ActiveTab);
          prevTab = this.ActiveTab
          newTab = true
        }
      }
    }
    // console.log(prevTab)
    let isProChange = this.props.userData.isPro !== nextProps.userData.isPro
    let newcolumn = [];
    if (Object.keys(feedList).length) {
      // console.log(Object.keys(feedList), this.state.feedListRef)
      newcolumn = Object.keys(feedList).filter((key, i) => (this.state.feedListRef.indexOf(key) === -1)).filter(itm => (itm));
    }
    let closedcolumn = [];
    if (this.state.feedListRef.length) {
      closedcolumn = this.state.feedListRef.filter((key, i) => (Object.keys(feedList).indexOf(key) === -1)).filter(itm => (itm))
    }

    // console.log(newcolumn, this.state.feedListRef, feedList)
    // console.log('new column:-',newcolumn)
    // console.log('closed column:-',closedcolumn, feedList, this.state.feedListRef)
    // console.log(prevTab, newTab)
    // console.log(this.state.storyState.pinnedSearchCount, nextProps.storyState.pinnedSearchCount)
    if (closedcolumn.length) {
      if (nextProps.storyState.closedpinnedcolumn > 0) {
        // console.log('here closed')
        // console.log(feedList, this.state.feedListRef)
        let newactiveindex = 0;
        let closedcolumnindex = this.state.feedListRef.indexOf(closedcolumn[0])
        if (closedcolumnindex === 0) {
          // console.log('here1')
          newactiveindex = closedcolumnindex + 1
        } else if (closedcolumnindex === this.state.feedListRef.length - 1) {
          // console.log('here2')
          newactiveindex = closedcolumnindex - 1
        } else if (closedcolumnindex > 0 && closedcolumnindex < this.state.feedListRef.length - 1) {
          // console.log('here3')
          newactiveindex = closedcolumnindex + 1
        }
        // console.log(this.state.feedListRef[newactiveindex])
        sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.state.feedListRef[newactiveindex]);
        newTab = true;
      }
      // let newactiveindex = 0;
      // let closedcolumnindex = this.state.feedListRef.indexOf(closedcolumn[0])
      // console.log(closedcolumnindex, this.state.feedListRef)
      // if(closedcolumnindex === this.state.feedListRef.length -1) {
      //   console.log('here2')
      //   newactiveindex = closedcolumnindex -1
      // } else if(closedcolumnindex > 0 && closedcolumnindex < this.state.feedListRef.length -1) {
      //   console.log('here3')
      //   newactiveindex = closedcolumnindex +1
      // } else if(closedcolumnindex === 0) {
      //   console.log('here1')
      //   newactiveindex = closedcolumnindex +1
      // }
      // console.log(newactiveindex, this.state.feedListRef, feedList)
      // console.log(this.state.feedListRef[newactiveindex])
      // sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.state.feedListRef[newactiveindex]);
      // newTab = true;
    } else if (newcolumn.length) {
      // console.log(newcolumn[0])
      let AllColNames = Object.keys(feedList);
      if (AllColNames.indexOf(newcolumn[0]) === (AllColNames.length - 1)) {
        NewColumnIsLastColumn = true;
      } else if (isMobile) {

        NewColumnIsLastColumn = true;

      }
      if (newcolumn[0].startsWith('SavedSearch')) {
        if (nextProps.storyState.pinnedSearchCount > 0) {
          sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, newcolumn[0]);
          newTab = true;
        }
      } else if (newcolumn[0].startsWith('Coin_Column_details')) {
        if (nextProps.storyState.pinnedcoindetailcolumn > 0) {
          sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, newcolumn[0]);
          newTab = true;
        }
      } else if (newcolumn[0].startsWith('Trending Mentions')) {
        if (nextProps.storyState.pinnedcoincolumn > 0) {
          sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, newcolumn[0]);
          newTab = true;
        }
      } else if (newcolumn[0].startsWith('Main Feeds')) {
        if (nextProps.storyState.pinnedmainfeeds > 0) {
          sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, newcolumn[0]);
          newTab = true;
        }
      } else {
        sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, newcolumn[0]);
        newTab = true;
      }
      // console.log('calling modal close')
      // nextProps.modalClose();

    } else if (this.props.existingColumnName !== nextProps.existingColumnName) {
      console.log('change in existing column name:-', nextProps.existingColumnName)
      sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, nextProps.existingColumnName);
      newTab = true;
    }
    // else if(this.state.storyState.tmDetailPreferences.length && (this.state.storyState.tmDetailPreferences.length < nextProps.storyState.tmDetailPreferences.length)) {
    //   console.log('here ->', `Coin_Column_details__${nextProps.storyState.tmDetailPreferences.length-1}`)
    //   sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, `Coin_Column_details__${nextProps.storyState.tmDetailPreferences.length -1}`);
    //   newTab = true;
    // } else if(this.state.storyState.searchCount < nextProps.storyState.searchCount) {
    //   console.log('change in search:-',this.state.storyState.searchCount,nextProps.storyState.searchCount)
    //   sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, 'Searched Feeds');
    //   newTab = true;
    // } else if(!this.state.RecentUpdateView && nextProps.storyState.RecentUpdateView) {
    //   console.log('change in main feeds:-',this.state.RecentUpdateView, nextProps.storyState.RecentUpdateView)
    //   sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, 'Main Feeds');
    //   newTab = true;

    // } else if(this.state.coin_column_preferences_length === 0 && nextProps.coin_column_preferences.length) {
    //   console.log('change in Top trending:-',this.state.coin_column_preferences_length, nextProps.coin_column_preferences.length)
    //   sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, 'Trending Mentions__0');
    //   newTab = true;
    //   // console.log(prevTab, newTab)
    // } 
    else if (!prevTab) {
      // console.log(prevTab, newTab)
      sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.ActiveTab);
      // if(nextProps.coin_column_preferences.length) {
      //   sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, 'Trending Mentions__0');
      // } else {
      //   sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, 'Main Feeds');
      // }
      newTab = true;
      // if(isProChange) {
      //   newTab = true;
      // }
    } else {
      if (isMobile) {
        // newTab = true;
      }
      // console.log(prevTab)
      // console.log(prevTab, newTab)
      // if (this.state.storyState.searchCount < nextProps.storyState.searchCount) {
      //   // console.log('searchc here')
      //   if (!nextProps.storyState.singleCoinSearch) {
      //     sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, 'Searched Feeds');
      //   } 

      //   newTab = true;
      // } else {
      // console.log('here');
      // console.log(prevTab)
      // console.log(this.newTabTM)
      // if(this.newTabTM && prevTab === 'Trending Mentions Details') {
      //   sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, 'Trending Mentions Details');
      //   newTab = true;
      // } else {
      // Object.keys(feedList).map((tabKey) => {
      // console.log(tabKey, this.state.FeedList)
      // if(!prevState.coin_column_preferences_length && nextProps.coin_column_preferences.length) {
      //   sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, 'Trending Mentions__0');
      // } else {
      //   sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, 'Main Feeds');
      // }

      // if (Object.keys(feedList).indexOf(prevTab) === -1) {
      //   console.log('tab closed')
      // console.log(nextProps.BeforeActiveColumn, nextProps.AfterActiveColumn)
      // if(typeof nextProps.BeforeActiveColumn !== 'undefined') {
      //   sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, nextProps.BeforeActiveColumn);
      //   newTab = true;
      // } else if(typeof nextProps.AfterActiveColumn !== 'undefined') {
      //   sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, nextProps.AfterActiveColumn);
      //   newTab = true;
      // }
      //   if (tabKey === 'Searched Feeds' ||(prevTab === 'Searched Feeds' && tabKey.startsWith('SavedSearch')) || tabKey === 'Trending Mentions Details') {
      //     // console.log(this.loadTime.format())
      //     // console.log(moment().format())
      //     // console.log(moment().diff(this.loadTime))
      //     if(tabKey === 'Trending Mentions Details') {
      //       if(moment().diff(this.loadTime) > 3500) {
      //         sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, tabKey);
      //         newTab = true;
      //       }
      //     } else {
      //       sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, tabKey);
      //       newTab = true;
      //     }



      //   }
      // }
      //   return null;
      // });
      // }

      // }
      // console.log(newTab)
      // if (!newTab) {
      //   if (Object.keys(feedList).indexOf(prevTab) === -1) {
      //     if(nextProps.coin_column_preferences.length) {
      //       sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, 'Trending Mentions__0');
      //     } else {
      //       sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, 'Main Feeds');
      //     }
      //   }
      // }
    }

    let { FeedListRef, BeforeActiveTab, AfterActiveTab } = this.getBeforeAfterActiveTab(
      feedList,
      sessionStorage.getItem(`${this.props.userData.username}lastActiveTab`)
    );
    // console.log( FeedListRef, BeforeActiveTab, AfterActiveTab)

    this.ActiveTab = sessionStorage.getItem(`${this.props.userData.username}lastActiveTab`);
    // console.log(this.ActiveTab)
    this.BeforeActiveTab = BeforeActiveTab;
    this.AfterActiveTab = AfterActiveTab;
    // console.log(this.ActiveTab)
    this.setState(
      {
        ...this.state,
        storyState: StoryState,
        TDeckDomRect: tdeckDom,
        TabDomRect: tabDom,
        FeedList: feedList,
        feedListRef: JSON.parse(JSON.stringify(FeedListRef)),
        // BeforeActiveTab: BeforeActiveTab,
        // AfterActiveTab: AfterActiveTab,
        CoinEventListcollapse: StoryState.CD_Collapsed_View,
        MainEventListcollapse: StoryState.Event_Collapsed_View,
        SearchListCollapse: StoryState.Search_Collapsed_View,
        NewTab: newTab,
        NewColumnIsLastColumn: NewColumnIsLastColumn,
        RecentUpdateView: StoryState.RecentUpdateView,
        searchSaveDatacount: Object.keys(StoryState.searchSaveData).length,
        coin_column_preferences_length: nextProps.coin_column_preferences.length,
        pinnedSearchCount: nextProps.storyState.pinnedSearchCount,
        columnorderinitload: nextProps.storyState.columnorderlist_initload,
        savedsearchinitload: nextProps.storyState.searchsavedata_initload
        // ActiveTab: sessionStorage.getItem(`${this.props.userData.username}lastActiveTab`),
      },
      () => {
        if (this.state.NewTab) {
          // console.log('calling modal close')
          nextProps.modalClose();
        }
        if (isMobile) {
          this.props.setSwipeableOptions({
            feedlist: this.state.FeedList,
            ActiveTab: this.ActiveTab,
            BeforeActiveTab: this.BeforeActiveTab,
            AfterActiveTab: this.AfterActiveTab,
          })
        }

      }
    );
  }
  columnLoadUpdate = (count) => {
    // console.log('loaded columns', count);
    // console.log('showing columns', Object.keys(this.state.FeedList));
    // console.log('columns in settings', this.props.storyState.columnorderlist);
    if (count === Object.keys(this.state.FeedList).length) {
      // this.props.modalClose();
      this.props.DisableLoader()
    }
  }
  resetNewTab = () => {
    setTimeout(() => {
      this.setState({
        ...this.state,
        NewTab: false
      }, () => {
        this.newTabTM = false
      })
    }, 200);
  }

  getBeforeAfterActiveTab = (feedList, ActiveTab) => {
    let feedListRef = Object.keys(feedList);
    let ActiveTabIndex = feedListRef.indexOf(ActiveTab);
    let BeforeActiveTabIndex = ActiveTabIndex > 0 ? ActiveTabIndex - 1 : -1;
    let AfterActiveTabIndex = ActiveTabIndex > -1 ? ActiveTabIndex + 1 : ActiveTabIndex;
    // console.log(ActiveTabIndex, BeforeActiveTabIndex, AfterActiveTabIndex)
    return {
      FeedListRef: feedListRef,
      BeforeActiveTab: feedListRef[BeforeActiveTabIndex],
      AfterActiveTab: feedListRef[AfterActiveTabIndex],
    };
  };

  resize = () => {
    let tdeckDom = this.setTDeckDomRect();
    let tabDom = this.setTabDomRect();
    this.setState({
      ...this.state,
      TDeckDomRect: tdeckDom,
      TabDomRect: tabDom,
    });
  };

  setTDeckDomRect = () => {
    return ReactDOM.findDOMNode(this.refs['tdeck']).getBoundingClientRect();
  };

  setTabDomRect = () => {
    if (this.refs['eventtabs']) {
      return ReactDOM.findDOMNode(this.refs['eventtabs']).getBoundingClientRect();
    }
    return {};
  };

  makeFeedList = (STRYPROPS) => {
    const {
      checkInSearch,
      TabMode,
      searchSaveData,
      showEarlyEvents,
      showAnalyzedEvents,
      TM_View,
      TickerViewDetails,
      coin_column_preferences,
      tmDetailPreferences,
      RecentUpdateView,
      HasColumnOrderSettings,
      columnorderlist,
      verifiednewcolumnordersettings,
      MenuFilter,
      columnorderlist_initload,
      searchsavedata_initload
    } = STRYPROPS.storyState;
    let FeedList = {};
    let hideAllFlag = false;
    if (typeof this.state !== 'undefined') {
      const { columnorderinitload, savedsearchinitload } = this.state

      // console.log('column order list loaded completely:-',columnorderlist_initload)
      // console.log('column order list loaded completely state:-', typeof columnorderinitload !== 'undefined' ? columnorderinitload : 'undefined')
      // console.log('column order list:-',columnorderlist)
      // console.log('saved search loaded completely:-',searchsavedata_initload, savedsearchinitload)
      // console.log('saved search loaded completely state:-',typeof savedsearchinitload !== 'undefined' ? savedsearchinitload : 'undefined')
      // console.log('search save data:-',searchSaveData)


      if (columnorderlist_initload && !columnorderinitload) {
        if (searchsavedata_initload) {
          this.props.syncsavedsearchcolumnordersettings()
        }
      } else if (searchsavedata_initload && !savedsearchinitload) {
        if (columnorderlist_initload) {
          this.props.syncsavedsearchcolumnordersettings()
        }
      }
      // console.log('column order settings:-',HasColumnOrderSettings)


    }
    // const { columnorderinitload, savedsearchinitload } = this.state
    // // console.log('column order settings:-',HasColumnOrderSettings)
    // console.log('column order list loaded completely:-',columnorderlist_initload)
    // console.log('column order list loaded completely state:-', typeof columnorderinitload !== 'undefined' ? columnorderinitload : 'undefined')
    // console.log('column order list:-',columnorderlist)
    // console.log('saved search loaded completely:-',searchsavedata_initload, savedsearchinitload)
    // console.log('saved search loaded completely state:-',typeof savedsearchinitload !== 'undefined' ? savedsearchinitload : 'undefined')
    // console.log('search save data:-',searchSaveData)
    let columnlist = []
    let newcolumnorder = []

    if (STRYPROPS.render === 'iframe') {
      // console.log(STRYPROPS.iframeasset)
      if (!STRYPROPS.iframeasset) {
        FeedList['Main Feeds'] = true;
        columnlist.push('Main Feeds')
        newcolumnorder.push({
          columntype: 'Main Feeds'
        })
      }
      if (STRYPROPS.iframeasset) {
        // console.log(searchSaveData)
        FeedList[`SavedSearch${STRYPROPS.iframeasset}`] = true;
        columnlist.push(`SavedSearch${STRYPROPS.iframeasset}`)
      }

    }
    else if (HasColumnOrderSettings) {

      // console.log('Menu Filter:-', MenuFilter)
      if (MenuFilter === 'Summary') {
        let renderingFilter = '';
        let savedsearchinorderlist = 0;
        if (TabMode) {
          if (coin_column_preferences.length) {
            coin_column_preferences.forEach((pref, i) => {
              if (pref.expanded === 'true') {
                FeedList[`Trending Mentions__${i}`] = true;
                columnlist.push(`Trending Mentions__${i}`)
                hideAllFlag = true;
              }
            })
          }
        }
        if (columnorderlist.length && !hideAllFlag) {
          let tmcolindex = 0;
          columnorderlist.forEach((column, colindex) => {

            // console.log(column)
            if (column.columntype === 'tm') {
              // console.log(coin_column_preferences)
              if (coin_column_preferences.length && coin_column_preferences[tmcolindex]) {
                FeedList[`Trending Mentions__${tmcolindex}`] = true;
                columnlist.push(`Trending Mentions__${tmcolindex}`)
                renderingFilter = 'Trending'
                // newcolumnorder.push(coin_column_preferences[tmcolindex])
                // newcolumnorder[newcolumnorder.length -1].columntype = 'tm'
                tmcolindex++;
              }

            } else if (column.columntype === 'tmcd') {
              // console.log(tmDetailPreferences)
              if (tmDetailPreferences.length) {
                tmDetailPreferences.forEach((pref, i) => {
                  if (pref.SecurityName === column.SecurityName && pref.SecurityTicker === column.SecurityTicker && pref.columnFilter === column.columnFilter) {

                    FeedList[`Coin_Column_details__${i}`] = true;
                    columnlist.push(`Coin_Column_details__${i}`)
                    // newcolumnorder.push(pref)
                    // newcolumnorder[newcolumnorder.length -1].columntype = 'tmcd'
                  }

                })
              }
            } else if (column.columntype === 'Main Feeds' && RecentUpdateView) {
              // console.log('Main Feeds')
              if (columnlist.indexOf('Main Feeds') === -1) {
                FeedList['Main Feeds'] = true;
                columnlist.push('Main Feeds')
                renderingFilter = 'Inbox'
                // newcolumnorder.push({
                //   columntype: 'Main Feeds'
                // })
              }

            } else if (column.columntype === 'SavedSearch') {
              // console.log(searchSaveData)
              if (searchSaveData[column.UserSavedDataID]) {
                FeedList[`SavedSearch${column.UserSavedDataID}`] = true;
                columnlist.push(`SavedSearch${column.UserSavedDataID}`)
                renderingFilter = 'Search'
                // newcolumnorder.push(searchSaveData[column.UserSavedDataID])
                // newcolumnorder[newcolumnorder.length -1].columntype = 'SavedSearch'
                savedsearchinorderlist++;
              } else {
                // console.log(searchSaveData, column)
              }
            }
          })
          // if (Object.keys(searchSaveData).length > savedsearchinorderlist) {
          //   let columnsearchids = []
          //   columnorderlist.forEach((column, colindex) => {
          //     if(column.columntype === 'SavedSearch' ) {
          //       columnsearchids.push(column.UserSavedDataID)
          //     }
          //   })
          //   let savedsearchids = Object.keys(searchSaveData)
          //   let hasmissingsearch = false;
          //   let newcolumnorderlist = JSON.parse(JSON.stringify(columnorderlist))
          //   savedsearchids.forEach((id, i) => {
          //     if(columnsearchids.indexOf(id) === -1) {
          //       FeedList[`SavedSearch${id}`] = true;
          //       columnlist.push(`SavedSearch${id}`)
          //       // newcolumnorderlist.push(searchSaveData[id])
          //       // newcolumnorderlist[newcolumnorderlist.length -1].columntype = 'SavedSearch'
          //       hasmissingsearch = true;
          //     }
          //   })
          //   // if(hasmissingsearch) {
          //   //   STRYPROPS.newColumnOrderSettings(newcolumnorderlist)
          //   // }
          // }
          // if(!verifiednewcolumnordersettings) {
          //   this.props.verifynewsettings(newcolumnorder);
          // }
        }
      } else if (MenuFilter === 'Trending') {
        if (TabMode) {
          if (coin_column_preferences.length) {
            coin_column_preferences.forEach((pref, i) => {
              if (pref.expanded === 'true') {
                FeedList[`Trending Mentions__${i}`] = true;
                columnlist.push(`Trending Mentions__${i}`)
                hideAllFlag = true;
              }
            })
          }
        }
        if (columnorderlist.length && !hideAllFlag) {
          // let filtering = false;
          let tmcolindex = 0;
          columnorderlist.forEach((column, colindex) => {
            if (column.columntype === 'tm') {
              if (coin_column_preferences.length && coin_column_preferences[tmcolindex]) {
                // if(filtering) {
                FeedList[`Trending Mentions__${tmcolindex}`] = true;
                // }
                // filtering = true;
                columnlist.push(`Trending Mentions__${tmcolindex}`)
                tmcolindex++;
              }
            } else if (column.columntype === 'tmcd' && column.columnFilter === 'Trending') {
              if (tmDetailPreferences.length) {
                tmDetailPreferences.forEach((pref, i) => {
                  if (pref.SecurityName === column.SecurityName && pref.SecurityTicker === column.SecurityTicker && pref.columnFilter === column.columnFilter) {

                    FeedList[`Coin_Column_details__${i}`] = true;

                    columnlist.push(`Coin_Column_details__${i}`)
                  }

                })
              }
            } else if (column.columntype === 'SavedSearch' && (typeof column.columnFilter !== 'undefined' && column.columnFilter === 'Trending')) {
              if (searchSaveData[column.UserSavedDataID]) {
                FeedList[`SavedSearch${column.UserSavedDataID}`] = true;
                columnlist.push(`SavedSearch${column.UserSavedDataID}`)
              } else {
                // console.log(searchSaveData, column)
              }
            } else {
              if (column.columntype === 'tmcd') {
                if (tmDetailPreferences.length) {
                  tmDetailPreferences.forEach((pref, i) => {
                    if (pref.SecurityName === column.SecurityName && pref.SecurityTicker === column.SecurityTicker && pref.columnFilter === column.columnFilter) {

                      columnlist.push(`Coin_Column_details__${i}`)
                    }

                  })
                }
              } else if (column.columntype === 'Main Feeds' && RecentUpdateView) {
                // console.log('Main Feeds')
                if (columnlist.indexOf('Main Feeds') === -1) {
                  // FeedList['Main Feeds'] = true;
                  // filtering = false;
                  columnlist.push('Main Feeds')
                }
              } else if (column.columntype === 'SavedSearch') {
                // console.log(searchSaveData)
                if (searchSaveData[column.UserSavedDataID]) {
                  // FeedList[`SavedSearch${column.UserSavedDataID}`] = true;
                  // filtering = false;
                  columnlist.push(`SavedSearch${column.UserSavedDataID}`)
                } else {
                  // console.log(searchSaveData, column)
                }
              }
            }
          })
        }
      } else if (MenuFilter === 'Inbox') {
        if (columnorderlist.length && !hideAllFlag) {
          // let filtering = false;
          let tmcolindex = 0;
          columnorderlist.forEach((column, colindex) => {
            if (column.columntype === 'Main Feeds' && RecentUpdateView) {
              if (columnlist.indexOf('Main Feeds') === -1) {
                FeedList['Main Feeds'] = true;
                columnlist.push('Main Feeds')
              }
            } else if (column.columntype === 'tmcd' && column.columnFilter === 'Inbox') {
              // console.log(tmDetailPreferences)
              if (tmDetailPreferences.length) {
                tmDetailPreferences.forEach((pref, i) => {
                  if (pref.SecurityName === column.SecurityName && pref.SecurityTicker === column.SecurityTicker && pref.columnFilter === column.columnFilter) {
                    // if(filtering) {
                    FeedList[`Coin_Column_details__${i}`] = true;
                    // }
                    columnlist.push(`Coin_Column_details__${i}`)
                  }

                })
              }
            } else if (column.columntype === 'SavedSearch' && (typeof column.columnFilter !== 'undefined' && column.columnFilter === 'Inbox')) {
              if (searchSaveData[column.UserSavedDataID]) {
                FeedList[`SavedSearch${column.UserSavedDataID}`] = true;
                columnlist.push(`SavedSearch${column.UserSavedDataID}`)
              } else {
                // console.log(searchSaveData, column)
              }
            } else {
              if (column.columntype === 'tm') {
                if (coin_column_preferences.length && coin_column_preferences[tmcolindex]) {

                  columnlist.push(`Trending Mentions__${tmcolindex}`)
                  tmcolindex++;
                }
              } else if (column.columntype === 'tmcd') {
                // console.log(tmDetailPreferences)
                if (tmDetailPreferences.length) {
                  tmDetailPreferences.forEach((pref, i) => {
                    if (pref.SecurityName === column.SecurityName && pref.SecurityTicker === column.SecurityTicker && pref.columnFilter === column.columnFilter) {

                      columnlist.push(`Coin_Column_details__${i}`)
                    }

                  })
                }
              } else if (column.columntype === 'SavedSearch') {
                // console.log(searchSaveData)
                if (searchSaveData[column.UserSavedDataID]) {

                  columnlist.push(`SavedSearch${column.UserSavedDataID}`)
                } else {
                  // console.log(searchSaveData, column)
                }
              }
            }


          })
        }
      } else if (MenuFilter === 'Search') {
        let savedsearchinorderlist = 0;
        // console.log(columnorderlist)
        if (columnorderlist.length && !hideAllFlag) {
          // let filtering = false;
          let tmcolindex = 0;
          columnorderlist.forEach((column, colindex) => {

            if (column.columntype === 'SavedSearch') {
              if (typeof column.columnFilter !== 'undefined' && column.columnFilter === 'Search') {
                if (searchSaveData[column.UserSavedDataID]) {
                  FeedList[`SavedSearch${column.UserSavedDataID}`] = true;
                  columnlist.push(`SavedSearch${column.UserSavedDataID}`)

                } else {
                  // console.log(searchSaveData, column)
                }
              } else {
                if (typeof column.columnFilter !== 'undefined' && column.columnFilter === 'Inbox') {
                  if (searchSaveData[column.UserSavedDataID]) {
                    columnlist.push(`SavedSearch${column.UserSavedDataID}`)

                  }
                } else if (typeof column.columnFilter === 'undefined') {
                  if (searchSaveData[column.UserSavedDataID]) {
                    FeedList[`SavedSearch${column.UserSavedDataID}`] = true;
                    columnlist.push(`SavedSearch${column.UserSavedDataID}`)

                  } else {
                    // console.log(searchSaveData, column)
                  }
                }
              }
              // console.log(searchSaveData)

            } else if (column.columntype === 'tmcd' && column.columnFilter === 'Search') {
              if (tmDetailPreferences.length) {
                tmDetailPreferences.forEach((pref, i) => {
                  if (pref.SecurityName === column.SecurityName && pref.SecurityTicker === column.SecurityTicker && pref.columnFilter === column.columnFilter) {
                    // if(filtering) {
                    FeedList[`Coin_Column_details__${i}`] = true;
                    // }
                    columnlist.push(`Coin_Column_details__${i}`)
                  }

                })
              }
            } else {
              if (column.columntype === 'tm') {
                if (coin_column_preferences.length && coin_column_preferences[tmcolindex]) {

                  columnlist.push(`Trending Mentions__${tmcolindex}`)
                  tmcolindex++;
                }
              } else if (column.columntype === 'tmcd') {
                // console.log(tmDetailPreferences)
                if (tmDetailPreferences.length) {
                  tmDetailPreferences.forEach((pref, i) => {
                    if (pref.SecurityName === column.SecurityName && pref.SecurityTicker === column.SecurityTicker && pref.columnFilter === column.columnFilter) {

                      columnlist.push(`Coin_Column_details__${i}`)
                    }

                  })
                }
              } else if (column.columntype === 'Main Feeds' && RecentUpdateView) {
                // console.log('Main Feeds')
                if (columnlist.indexOf('Main Feeds') === -1) {
                  // FeedList['Main Feeds'] = true;
                  // filtering = false;
                  columnlist.push('Main Feeds')
                }
              }
            }
          })
          // if (Object.keys(searchSaveData).length > savedsearchinorderlist) {
          //   let columnsearchids = []
          //   columnorderlist.forEach((column, colindex) => {
          //     if(column.columntype === 'SavedSearch' ) {
          //       columnsearchids.push(column.UserSavedDataID)
          //     }
          //   })
          //   let savedsearchids = Object.keys(searchSaveData)
          //   let hasmissingsearch = false;
          //   let newcolumnorderlist = JSON.parse(JSON.stringify(columnorderlist))
          //   savedsearchids.forEach((id, i) => {
          //     if(columnsearchids.indexOf(id) === -1) {
          //       FeedList[`SavedSearch${id}`] = true;
          //       columnlist.push(`SavedSearch${id}`)
          //       // newcolumnorderlist.push(searchSaveData[id])
          //       // newcolumnorderlist[newcolumnorderlist.length -1].columntype = 'SavedSearch'
          //       hasmissingsearch = true;
          //     }
          //   })
          //   // if(hasmissingsearch) {
          //   //   STRYPROPS.newColumnOrderSettings(newcolumnorderlist)
          //   // }
          // }
        }
      }
      // console.log(columnorderlist, columnlist, searchSaveData, tmDetailPreferences)
    } else {
      if (TabMode) {
        if (coin_column_preferences.length) {
          coin_column_preferences.forEach((pref, i) => {
            if (pref.expanded === 'true') {
              FeedList[`Trending Mentions__${i}`] = true;
              columnlist.push(`Trending Mentions__${i}`)
              hideAllFlag = true;
            }
          })
        }
      }
      if (!hideAllFlag) {

        if (coin_column_preferences.length) {
          coin_column_preferences.forEach((pref, i) => {
            FeedList[`Trending Mentions__${i}`] = true;
            columnlist.push(`Trending Mentions__${i}`)
            newcolumnorder.push(pref)
            newcolumnorder[newcolumnorder.length - 1].columntype = 'tm'
          })
        }

        if (TickerViewDetails) {
          FeedList['Trending Mentions Details'] = true;
          columnlist.push('Trending Mentions Details')
        }
        if (tmDetailPreferences.length) {
          tmDetailPreferences.forEach((pref, i) => {
            FeedList[`Coin_Column_details__${i}`] = true;
            columnlist.push(`Coin_Column_details__${i}`)
            newcolumnorder.push(pref)
            newcolumnorder[newcolumnorder.length - 1].columntype = 'tmcd'
          })
        }
        if (RecentUpdateView) {
          FeedList['Main Feeds'] = true;
          columnlist.push('Main Feeds')
          newcolumnorder.push({
            columntype: 'Main Feeds'
          })
        }


        if (Object.keys(searchSaveData).length) {
          Object.keys(searchSaveData).map((saveId) => {
            FeedList[`SavedSearch${saveId}`] = true;
            columnlist.push(`SavedSearch${saveId}`)
            newcolumnorder.push(searchSaveData[saveId])
            newcolumnorder[newcolumnorder.length - 1].columntype = 'SavedSearch'
            return null;
          });
        }

        if (STRYPROPS.searchData.activeStatus) {
          FeedList['Searched Feeds'] = true;
          columnlist.push('Searched Feeds')
        }
      }
      // console.log(columnorderlist, newcolumnorder, columnlist, searchSaveData, tmDetailPreferences)
      STRYPROPS.newColumnOrderSettings(newcolumnorder)
    }


    if (columnlist.length) {
      STRYPROPS.setcolumnlist(columnlist);
    }

    // if (showEarlyEvents) {
    //   if (TabMode) {
    //     FeedList['Breaking Feeds'] = true;
    //   } else {
    //     if (STRYPROPS.homeviewport.width < 1350 && checkInSearch) {
    //     } else {
    //       FeedList['Breaking Feeds'] = true;
    //     }
    //   }
    // }
    // if (showAnalyzedEvents) {
    //   FeedList['Analyzed Feeds'] = true;
    // }
    return FeedList;
  };

  storyTabSelect = (TabType) => {
    // console.log(TabType)
    if (typeof this.state.FeedList[`${TabType}`] !== 'undefined') {
      let { FeedListRef, BeforeActiveTab, AfterActiveTab } = this.getBeforeAfterActiveTab(this.state.FeedList, TabType);
      // console.log(FeedListRef, BeforeActiveTab, TabType,  AfterActiveTab)
      this.ActiveTab = TabType;
      this.BeforeActiveTab = BeforeActiveTab;
      this.AfterActiveTab = AfterActiveTab;
      if (TabType === 'Trending Mentions Details') {
        this.newTabTM = true;
      }
      sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.ActiveTab);
      // this.setState(
      //   {
      //     ...this.state,
      //     // ActiveTab: TabType,
      //     feedListRef: JSON.parse(JSON.stringify(FeedListRef)),
      //     // BeforeActiveTab: BeforeActiveTab,
      //     // AfterActiveTab: AfterActiveTab,
      //   },
      //   () => {
      //     // console.log(this.state.ActiveTab)
      //     sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.ActiveTab);
      //     // sessionStorage.setItem(`${this.props.userData.username}lastActiveTab`, this.state.ActiveTab);
      //   }
      // );
    }
  };

  toggleAutoRefresh = () => {
    this.setState(
      {
        ...this.state,
        tmAutoRefresh: !this.state.tmAutoRefresh,
      },
      () => {
        this.props.togglePopup(false);
        this.props.updateTMAutoRefresh(this.state.tmAutoRefresh);
      }
    );
  };

  toggleAutoRefreshtooltip = (status) => {
    const containerWidth = 170;
    let ContainerRect = this.tmarWrap.getBoundingClientRect();
    let Pos = {
      left: ContainerRect.left - containerWidth,
      top: ContainerRect.bottom + 10,
    };
    let tipText = 'ON / OFF Auto refresh';
    const containerStyleClass = 'infoTip normal-right';
    if (status) {
      this.props.togglePopup(status, tipText, Pos, containerStyleClass);
    } else {
      this.props.togglePopup(status);
    }
  };

  toggleSaveSearchhover = (status) => {
    this.setState({
      ...this.state,
      pinHover: status,
    });
  };


  naceHover = (e, showState, but) => {
    e.preventDefault();
    if (showState && !isMobile) {
      this.setState({
        ...this.state,
        [`${but}`]: showState,
      });
    } else {
      this.setState({
        ...this.state,
        [`${but}`]: false,
      });
    }
  };

  toggleAllSecFilter = () => {
    this.setState(
      {
        ...this.state,
        AllSecFilter: !this.state.AllSecFilter,
      },
      () => {
        this.props.filterSecurities(this.state.AllSecFilter);
      }
    );
  };

  changeTMFrame = (frame) => {
    this.props.setTMFrame(frame);
  };
  closeTmDetailsHover = (e, state) => {
    this.setState({
      ...this.state,
      TMCloseHover: state,
    });
  };

  collapseCoinDetailsHover = (e, status) => {
    this.setState(
      {
        ...this.state,
        coinDetailsHover: status,
      },
      this.props.toggleOverlay(status, this.OverlayRef, 'Watch search bar video tutorial')
    );
  };

  collapseCoinDetails = (e) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        coinDetailsCollapse: this.state.coinDetailsCollapse ? false : true,
      },
      () => {
        // console.log(this.state.coinDetailsCollapse);
      }
    );
  };

  collapseMainEventList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log(this.state.MainEventListcollapse)
    // this.setState({
    //     ...this.state,
    //     MainEventListcollapse: !this.state.MainEventListcollapse,
    // },() => {
    //     // console.log(this.state.MainEventListcollapse);

    // });
    this.props.toggleColumnCollapse('Event_Collapsed_View', !this.state.MainEventListcollapse);
  };
  collapseMainEventListHover = (e, status) => {
    this.setState({
      ...this.state,
      mainEventListcollapseHover: status,
    });
  };

  collapseCoinColumnList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log(this.state.CoinEventListcollapse)
    // this.setState({
    //     ...this.state,
    //     CoinEventListcollapse: !this.state.CoinEventListcollapse,
    // },() => {
    //     // console.log(this.state.CoinEventListcollapse);

    // });
    this.props.toggleColumnCollapse('CD_Collapsed_View', !this.state.CoinEventListcollapse);
  };


  collapseCoinColumnListHover = (e, status) => {
    this.setState({
      ...this.state,
      coinEventListcollapseHover: status,
    });
  };
  collapseSearchList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log(this.state.SearchListCollapse)
    // this.setState({
    //     ...this.state,
    //     SearchListCollapse: !this.state.SearchListCollapse,
    // },() => {
    //     // console.log(this.state.SearchListCollapse);

    // });
    this.props.toggleColumnCollapse('Search_Collapsed_View', !this.state.SearchListCollapse);
  };
  collapseSearchListHover = (e, status) => {
    this.setState({
      ...this.state,
      searchEventListcollapseHover: status,
    });
  };
  collapseSavedSearchEventListHover = (e, status) => {
    this.setState({
      ...this.state,
      savedSearchEventListcollapseHover: status,
    });
  };



  render() {
    const {
      storyState,
      TDeckDomRect,
      FeedList,
      NewTab,
      NewColumnIsLastColumn,
      ActiveTab,
      BeforeActiveTab,
      AfterActiveTab,
      pinHover,
      ssCloseHover,
      TMCloseHover,
      coinDetailsHover,
      coinDetailsCollapse,
      MainEventListcollapse,
      CoinEventListcollapse,
      SearchListCollapse,
      // SavedSearchListCollapse,
      mainEventListcollapseHover,
      searchEventListcollapseHover,
      savedSearchEventListcollapseHover,
      hidePreferenceInSavedSearchFlag,
    } = this.state;
    // console.log(NewTab, this.ActiveTab)
    let FeedListKeys = Object.keys(FeedList);

    let StoryView = [];
    let colRect = {};
    let savedSearchTabbedMenu = [];
    let TickerName = this.props.TickerViewDetails
      ? Object.keys(this.props.TickerData).length //this.props.TickerData !== {}
        ? this.props.TickerData.SecurityName
        : ''
      : '';
    let TickerSymbol = this.props.TickerViewDetails
      ? Object.keys(this.props.TickerData).length //this.props.TickerData !== {}
        ? this.props.TickerData.SecurityTicker
        : ''
      : '';
    let cc_Preferences = {};
    let cc_index = undefined;
    let coinColumnIndex = undefined;
    let coindetail_Preferences = {};

    if (storyState.TabMode) {
      FeedListKeys.map((key, i) => {
        let iframewidth = TDeckDomRect.width >= 600 ? 600 : TDeckDomRect.width
        let iframeleft = (TDeckDomRect.width / 2) - (iframewidth / 2);

        colRect = {
          width: this.props.render === 'iframe' ? `${TDeckDomRect.width}px` : `${i === 0 ? TDeckDomRect.width - 20 : TDeckDomRect.width - 20}px`,
          left: this.props.render === 'iframe' ? `${iframeleft}px` : `${i === 0 ? 2 : i === 1 ? ((TDeckDomRect.width - 20) * i) + 1 : (TDeckDomRect.width - 20) * i}px`,
        };

        if (key.startsWith('Trending Mentions_')) {
          cc_index = key.split('__').length > 1 ? parseInt(key.split('__')[1]) : 0;
          cc_Preferences = JSON.parse(JSON.stringify(storyState.coin_column_preferences[cc_index]));
          // console.log(cc_Preferences)
          if (cc_Preferences.expanded === 'true') {

            colRect.width = `${parseInt(colRect.width.replace('px', '')) + 12}px`
          }
        } else if (key.startsWith('Coin_Column_details_')) {
          coinColumnIndex = key.split('__').length > 1 ? parseInt(key.split('__')[1]) : 0;
          coindetail_Preferences = JSON.parse(JSON.stringify(storyState.tmDetailPreferences[coinColumnIndex]));
        }
        // console.log(coindetail_Preferences)
        StoryView.push(
          <TweetDeckColumn
            {...this.props}
            listkey={key}
            listKeyIndex={i}
            colLength={FeedListKeys.length}
            // TabActive={key === ActiveTab}
            newTab={NewTab}
            TabActive={key === this.ActiveTab}
            // BeforeTabActive={key === BeforeActiveTab}
            BeforeTabActive={key === this.BeforeActiveTab}
            // AfterTabActive={key === AfterActiveTab}
            AfterTabActive={key === this.AfterActiveTab}
            homeviewport={this.props.homeviewport}
            colRect={colRect}
            key={`${key}${i}`}
            storyState={storyState}
            // parentRect={TDeckDomRect}
            TickerName={TickerName}
            TickerSymbol={TickerSymbol}
            TickerDetailsCollapse={coinDetailsCollapse}
            collapseTickerDetails={this.collapseCoinDetails}
            storyTabSelect={this.storyTabSelect}
            collapseMainEventList={this.collapseMainEventList}
            collapseCoinColumnList={this.collapseCoinColumnList}
            collapseSearchList={this.collapseSearchList}
            MainEventListcollapse={MainEventListcollapse}
            CoinEventListcollapse={CoinEventListcollapse}
            SearchListCollapse={SearchListCollapse}
            hidePreferenceInSearch={this.hidePreferenceInSearch}
            hidePreferenceInSearchFlag={this.state.hidePreferenceInSearchFlag}
            hidePreferenceInSavedSearchFlag={hidePreferenceInSavedSearchFlag}
            hidePreferenceInSavedSearch={this.hidePreferenceInSavedSearch}
            SecurityName={typeof coindetail_Preferences.SecurityName !== 'undefined' ? coindetail_Preferences.SecurityName : ''}
            SourceFilter={coindetail_Preferences.sourceFilter ? coindetail_Preferences.sourceFilter : ''}
            cc_index={cc_index}
            coinColumnIndex={coinColumnIndex}
            coindetailprefs={coindetail_Preferences}
            closecoincolumn={this.closecoincolumn}
          />
        );



        return null;
      });
    } else {
      let finalColumnProp = {}
      FeedListKeys.map((key, i) => {
        let iframewidth = TDeckDomRect.width >= 600 ? 600 : TDeckDomRect.width
        let iframeleft = (TDeckDomRect.width / 2) - (iframewidth / 2);
        colRect = {
          //width of column is ClientWidth/3
          width: this.props.render === 'iframe' ? `${TDeckDomRect.width}px` : `${i === 0 ? TDeckDomRect.width / 3 - 35 : TDeckDomRect.width / 3 - 25}px`,
          left: this.props.render === 'iframe' ? `${iframeleft}px` : `${i === 0 ? 10 : (TDeckDomRect.width / 3 - 15) * i}px`,

          top: `${TDeckDomRect.top}px`,
        };
        if (i === FeedListKeys.length - 1) {
          // finalColumnLeft = colRect.left
          finalColumnProp = {
            width: `${10}px`,
            left: this.props.render === 'iframe' ? `${iframeleft + iframewidth + 1}px` : `${(TDeckDomRect.width / 3 - 15) * (i + 1)}px`,

            top: `${TDeckDomRect.top}px`,
          }
        }
        if (key.startsWith('Trending Mentions_')) {
          cc_index = key.split('__').length > 1 ? parseInt(key.split('__')[1]) : 0;
          cc_Preferences = JSON.parse(JSON.stringify(this.props.coin_column_preferences[0]));

        } else if (key.startsWith('Coin_Column_details_')) {
          coinColumnIndex = key.split('__').length > 1 ? parseInt(key.split('__')[1]) : 0;
          coindetail_Preferences = JSON.parse(JSON.stringify(storyState.tmDetailPreferences[coinColumnIndex]));
        }
        // console.log(coindetail_Preferences)
        StoryView.push(
          <TweetDeckColumn
            {...this.props}
            listkey={key}
            listKeyIndex={i}
            colLength={FeedListKeys.length}
            homeviewport={this.props.homeviewport}
            colRect={colRect}
            key={`${key}${i}`}
            storyState={storyState}
            // parentRect={TDeckDomRect}
            TickerName={TickerName}
            TickerSymbol={TickerSymbol}
            TickerDetailsCollapse={coinDetailsCollapse}
            collapseTickerDetails={this.collapseCoinDetails}
            storyTabSelect={this.storyTabSelect}
            collapseMainEventList={this.collapseMainEventList}
            collapseCoinColumnList={this.collapseCoinColumnList}
            collapseSearchList={this.collapseSearchList}
            MainEventListcollapse={MainEventListcollapse}
            CoinEventListcollapse={CoinEventListcollapse}
            SearchListCollapse={SearchListCollapse}
            // SavedSearchListCollapse={SavedSearchListCollapse}
            hidePreferenceInSearch={this.hidePreferenceInSearch}
            hidePreferenceInSearchFlag={this.state.hidePreferenceInSearchFlag}
            hidePreferenceInSavedSearchFlag={hidePreferenceInSavedSearchFlag}
            hidePreferenceInSavedSearch={this.hidePreferenceInSavedSearch}
            SecurityName={typeof coindetail_Preferences.SecurityName !== 'undefined' ? coindetail_Preferences.SecurityName : ''}
            SourceFilter={coindetail_Preferences.sourceFilter ? coindetail_Preferences.sourceFilter : ''}
            cc_index={cc_index}
            coinColumnIndex={coinColumnIndex}
            coindetailprefs={coindetail_Preferences}
            closecoincolumn={this.closecoincolumn}
          ></TweetDeckColumn>
        );
        if (this.props.render !== 'iframe') {
          if (i === FeedListKeys.length - 1) {
            StoryView.push(<TweetDeckFiller colRect={finalColumnProp} key={`tweetdeckfiller`} />)
          }
        }

        return null;
      });

    }
    // console.log(NewTab)

    if (isMobile) {
      // console.log(this.ActiveTab)
      if (NewTab) { //&& NewColumnIsLastColumn
        // console.log('newTab:', NewTab)
        let scrollDiv = document.getElementsByClassName('cScroll-Mob')[0];
        if (scrollDiv) {

          setTimeout(() => {
            scrollDiv.scroll({
              left: ((scrollDiv.scrollWidth / FeedListKeys.length) * FeedListKeys.indexOf(this.ActiveTab)) - 10,
              behavior: 'smooth'
            })
          }, 250)

        }
      }
    } else {
      if (NewTab) { // && NewColumnIsLastColumn
        var myDiv = document.getElementsByClassName('cScrollX-Cntrl')[0];
        if (myDiv) {
          setTimeout(() => {
            let ScrollTo = ((myDiv.scrollWidth / FeedListKeys.length) * FeedListKeys.indexOf(this.ActiveTab));
            if (FeedListKeys.length > 3) {
              if (this.ActiveTab.startsWith('Trending Mentions__')) {
                if (myDiv.scrollLeft > ScrollTo) {
                  myDiv.scrollLeft = NewTab === true ? ScrollTo : myDiv.scrollLeft;
                }
              } else {
                myDiv.scrollLeft = NewTab === true ? ScrollTo : myDiv.scrollLeft;
              }

            }

            this.resetNewTab();
          }, 200);

        }
      }
    }
    return (

      <div ref={`tdeck`}>
        <InitialColumnLoader columnLoadUpdate={this.columnLoadUpdate} />
        {StoryView}
      </div>

    );
  }
}
const mapStateToProps = (state, ownProps) => {
  // console.log(state.trendingMentions.tmDetailPreferencesDataStore)

  return {
    existingColumnName: state.settings.existingColumnName,
    render: state.settings.render,
    iframeasset: state.settings.iframeasset,
    iframeassetdata: state.settings.iframeassetdata,
    lang: state.settings.lang,
    Theme: state.app_preferences.preferences.user_interface.Theme,
    // searchSaveData: state.search.searchSaveData,
    // BeforeActiveColumn: state.settings.BeforeActiveColumn,
    // AfterActiveColumn: state.settings.AfterActiveColumn,
    // ActiveColumn: state.settings.ActiveColumn


  };
};

const mapDispatchToProps = {
  setcolumnlist,
  syncsavedsearchcolumnordersettings,
  DisableLoader
};

export default connect(mapStateToProps, mapDispatchToProps)(TweetDeck);


