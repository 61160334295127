import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import './appLoaderSpinner.css';
import { APPPATH } from '../../../../modules/helper.module';
import { connect } from 'react-redux';
import APPLoader from '../iforexloader/Iforexloader';

class SpinnerLoader extends Component {
    constructor(props) {
      super();
      this.state = {
        //   show: true
        viewSize: props.viewSize || 'sm'
      }
      this.close = '';
    }
    componentDidMount() {
        if(this.state.viewSize === 'sm') {
            this.close = setTimeout(() => {
            //     window.location.href = APPPATH.AppUrl;
            this.props.modalClose();
            },31000)
        }
        
    }

    componentWillUnmount() {
        clearTimeout(this.close);
    }
    

    render() {
        let Theme = this.props.forcetheme || this.props.Theme
        return (
            
            <div className={`full-width-height ${Theme}`} style={{position: 'relative', backgroundColor: Theme === 'dark' ? 'rgba(34,34,34,0.5)' : 'rgba(255,255,255,0.5)', left: '0px', marginTop: "20px"}}>
                <div className={`loader full-width-height ${this.state.viewSize}`}></div>
            </div>
            
        );
    }

}




const mapStateToProps = (state, ownProps) => {
    return {
      Theme: state.app_preferences.preferences.user_interface.Theme,
      forcetheme: state.settings.forcetheme
    };
};
  
const mapDispatchToProps = {
    
};

export default connect(mapStateToProps, mapDispatchToProps)(SpinnerLoader);